@import '../../App.scss';
.error-compo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
         .btn{
        background-color:   $main-color;
        border: none;
        color: #fff;
        padding: 4px 13px;
        margin-right: 10px;
        transition: all 0.2s ease-in ;
    }.btn:hover{
        background-color: $second-color;
        border: none;
    }
    img{
        width: 130px;
        height: 130px;
    }
}