
@import '../../App.scss';


.css-79ws1d-MuiModal-root,.css-8ndowl{
    position: fixed;
    // z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 99999999 !important;
    .css-1wnsr1i{
        border: none !important;
        box-shadow: none !important;
        border-radius: 5px;
        width: 500px;
        padding: 0px 0px 60px 0px !important;
        max-height: 650px;
        overflow-y: scroll;
        @media (max-width: 620px) {
            width: 340px !important;
        }
        @media (max-width: 340px) {
            width: 300px !important;
        }
        .head{
            padding: 12px 15px;
            width: 100%;
            background:  $main-color;
            color:  #fff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            margin-bottom: 30px;
            .title-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon{
                font-size: 15px;
                color: #eee;
            }
            }
        }
        .modal-iamge{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            img{
                width: 150px;
                height: 150px;
                border-radius: 5px;
                margin: auto;
            }
        }
        .img-title{
            display: flex;
            justify-content: center;
            align-items: center;
            p{
                font-weight: bold;
                font-size: 22px;
                columns: #555;
            }
        }
        .button-list{
            display: flex;
            justify-content: center;
            align-content: center;
            gap: 15px;
            .btn-mail{
                background-color: #fff;
                border : 1px solid $main-color;
                transition: all 0.3s ease;
            }
            .btn-mail:hover{
                background-color : $main-color;
                color:  #fff;
            }
            .btn-phone{
                background-color: #fff;
                border : 1px solid #dd6511;
            }
            .btn-phone:hover{
                background-color : #dd6511;
                color:  #fff;
            }
        }
        .details{
            display: flex;
            justify-content: space-evenly;
            align-content: center;
            gap: 15px;
            .addres,.role{
                font-size: 18px;
                color: #222;
                font-weight: bold;
                span{
                    color: #999;
                    font-weight: 600;
                    margin-left: 10px;
                }
            }
        }
    }
}

.waning{
    padding: 0 10px;
    p{
        width: 80%;

    }
    .btnleist-delte{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        .btn-n{
            background-color: gray;
            color: #fff;
            border: none;
            padding: 8px 30px;
        }
        .btn-y{
            background-color: crimson;
            color: #fff;
            border: none;
            padding: 8px 30px;  
        }
    }
}