@import  '../../App.scss';

.error-page{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
    z-index: 999999;
    img{
        width: 350px;
        height: 350px;
    }
    .btn-primary{
        background-color: $main-color;
        border: none;
    }
}