@import '../../App.scss';

.login{
    background: linear-gradient(rgba(56,59,58,.5),rgba(5,6,5,.9)), url('../../assits/doctor.jpg') no-repeat ;
    background-size: cover;
    background-color: $main-color;
    width: 100%;
    height: 100vh;
    padding: 30px 0;
    position: fixed;
    z-index: 9999;
    form{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .logo{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            h2{
                color: #fff;
                font-size: 40px;
                font-weight: bold;
            }
            @media screen and (min-width : 450px ) {
                 width:440px;
            height: 170px;
            }
            img{
                width: fit-content;
                height: 60%;
            }
        }
        .box-login{
            background-color: #ffffff52;
            margin: 10px !important;
            @media screen and (min-width : 950px ) {
                width:650px;
           }
            max-width: 650px;
            min-height: 450px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            gap: 10px;
            padding: 30px 15px !important;
            border-radius: 8px;
            
            .inps{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 10px;
                .inp-icon{
                    width: 80%;
                    position: relative;
                    span{
                        display: none;
                        color: red;
                        background-color: #eee;
                        padding: 5px;
                        border-radius: 5px;
                        width : 90%;
                        font-size: 13px;
                    } 
                    input:invalid[focused='true'] ~ span{
                        display: block;
                    }
                    .icon{
                        position: absolute;
                        color: rgb(118, 118, 118);
                        top: 5px;
                        font-size: 20px;
                        left: 5px;
                    }
                }
                p{
                    font-size: 20px;
                    text-align: center;
                    color: rgba(20, 29, 70, 1);
                }
                input{
                    font-size: 12px;
                    padding: 8px 30px;
                    border-radius: 8px;
                    border:none;
                    width:100%;
                    margin-bottom: 5px;
                }
                
            }
            .text-re{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 70%;
                margin: 20px 20px 5px 20px;
                .check{
                    display: flex;
                    justify-content: center;
                    
                    input{
                        width: 8px;
                        height: 8px;
                        padding: 9px;
                        border-radius: 2px;
                        margin-right: 4px;
                    }
                }
                p{
                    font-size: 16px;
                    
                }
            }
            .btn{
                background-color: $main-color;
                color:  #eee;
                transition: all 0.3s ease;
                border-radius: 8px;
                width: 75%;
            }
            .btn:hover{
                background-color: #0e3603;
            }
            .sign-up-ask{
                font-size: 22px;
                .link{
                    font-size: 18px;
                    color: #fff;
                    transition: all 0.3s ease;
                }
                .link:hover{
                    color: #0e3603;
                }
            }
            hr{
                height: 1px;
                background-color: rgb(20, 29, 70);
                width: 80% !important;
                margin: 0;
            }
            .policy-ask p{
                font-size: 14px;
            }
        }
    }
}

input:focus{
    outline: none !important;
}

