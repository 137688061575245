@import '../../App.scss';

.topbar{
    position: sticky;
    // z-index: 9999;
    margin-bottom: 20px;
    // border-bottom: 0.5px solid rgb(230, 227, 227);
    box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
    padding-bottom: 10px;
    padding-top: 6px;
    padding-right: 10px;
    .dropdown12{
        margin: 0;
        padding: 0;
        width: 18px;
        display: flex;
        justify-content: center;
        button{
            color: #777;
        }
    }
    .logo{
        display: flex;
        justify-content: center;
        cursor: pointer;
        gap: 5px;
        @media (max-width: 375px) {
            display: none !important;
        }
        p{
            margin: 0 !important;
            color:  rgba(20, 29, 70, 1);
            font-size: 19px;
            // font-weight: bold;
        }
        .icon{
            color:  rgba(20, 29, 70, 1);;
            width: 40px;
            height: 40px;
        }
    }
}.topbar_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 10px;
    p{
        margin-bottom: 0;
        cursor: pointer;
    }
    .list{
        display: none !important;
        @media (max-width: 1200px) {
            display: block !important;
        }
    }
    .inp-search{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        padding: 2px 8px;
        .input-search{
            border: none;
            color: #555;
        background-color: transparent;
        }
        .input-search:focus{
            outline: none;
            
            
        }
    }
    .big-icons-nav{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        font-size:20px ;
        cursor: pointer;
        @media (max-width: 375px) {
           width: 100%;
        }
        .icon-topbar{
            position: relative;
            color: $main-color;
            display: flex;
            justify-content: center;
            align-items: center;
            .sp{
                background-color: $main-color;
                color: white;
                font-size:12px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 15px;
                right: -7px;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                padding: 3px;
            }
            .image{
                width: 40px;
                height: 40px;
                border-radius: 50%;background-color: $main-color;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .box-noti-pop{
                position: absolute;
                top: 13px;
                right: 24px;
                min-width: 150px;
                text-align: center;
                max-height: 140px;
                background-color: #fff;
                z-index: 9999999;
                overflow-y: scroll;
                min-width: 350px;
                border-radius: 2%;
                box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
                transition: all 0.4s ease-in;
            
                .box-noti{
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 2;
                    border-bottom: 1px solid lightgray;
                    padding-bottom: 3px;
                    background-color: #f9f9f9;
                    padding:8px 10px;
                    .header-noti{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        cursor: pointer;
                        .icons{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            svg{
                                width: 20px;
                                height: 20px;
                                margin-top: -12px;
                                color: #555;
                                cursor: pointer;
                            }
                        }
                        h6{
                            color: $main-color;
                            font-weight: bold;
                            font-size: 17px;
                            margin-bottom: 10px;
                        }
                    }
                    p{
                        color: #444;
                        font-size: 12px;
                        text-align: left;
                        margin-bottom: 2px;
                    }
                }
                .title-nafi{
                    font-size: 17px;
                    padding: 5px;
                    border-bottom: 1px solid #2CD8AE;
                    margin-bottom: 3px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    transition: all 0.4s ease-in;
                    img{
                        width: 132px;
                        height: 65px;
                        border-radius: 50%;
                        margin-right: 14px;
                        border: 4px solid #2CD8AE;
                    }
                    .name{
                        font-weight: 700;
                    }
                    .date{
                        font-weight: 700;
                        font-style: italic;
                        margin-left: 10px;
                        color : gray
                    }
                }
            }
        }
    }
}   

.name-profile{
    margin: auto;
    border-bottom: 1px solid #999;
    width: 100%;
    padding-bottom: 5px;
    color:rgba(20, 29, 70, 1) !important
}