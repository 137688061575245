@import '../../App.scss';
.datatable{
    height: 600px;
    padding: 10px;
    .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel,.css-1chpzqh {
        padding-top: 15px;
    }
    .css-levciy-MuiTablePagination-displayedRows,.css-1chpzqh{
        padding-top: 15px;
    }
    .cellWithImg{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        .cellImg{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-size: cover;
            margin-right: 10px;
        }
    }
    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked,.css-1nr2wod.Mui-checked{
        color: $main-color
    }
    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track,.css-1nr2wod.Mui-checked + .MuiSwitch-track{
        background-color: $second-color;

    }
    .list-icon{
        position: relative;
        // border : 1px solid rgba(20, 29, 70, 1);
        border-radius: 50%;
        padding: 5px;
         display: flex;
         justify-content: center;
         align-items: center;
         .box-icon{
            position: absolute;
            padding: 5px;
            left: 11px;
            width: 111px;
            top: 30px;
            z-index: 999999;
            display: flex;
            justify-content: center;
            /* align-items: center; */
            flex-direction: column;
            background-color: #fff;
            box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
            /* background-color: #eee; */
            /* display: none; */
            gap: 12px;
            .icon-in{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
            }
            .viwe{
                color: #e87f33;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap : 10px;
                margin-bottom: 2px;
                cursor: pointer;
            }
            .edit{
                color:  rgb(105, 128, 232) !important;
                transition: all 0.2s ease;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap : 10px;
                margin-bottom:2px;
                cursor: pointer;

            }.edit:hover{
                color :rgba(20, 29, 70, 1);
            }
            .delete{
                color: gray;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap : 10px;
                margin-bottom: 2px;
                transition: all 0.2s ease;
                cursor: pointer;

            }.delete:hover{
                    color: rgba(255, 0, 0, 0.82);
                }
        }
    }
    
}

.css-5wly58-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    overflow: initial !important;
}

.MuiDataGrid-row{
    color: #666;
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow{
    color:  #000;
}

.custom-header {
    background-color: black; /* Set the background color for header cells */
    color: white; /* Set the text color for header cells */
  }
  
  .even-row {
    background-color: #ffffff; /* Set the background color for even rows */
  }
  
  .odd-row {
    background-color: #f0f0f030; /* Set the background color for odd rows */
  }


.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 100px;
   
}


.cellWithStatus {
    padding: 10px;
    border-radius: 12px;

    &.Active {
    background-color: rgba(0, 128, 0, 0.05);
    color: green;
    }
    &.Deactive {
    background-color: rgba(255, 0, 0, 0.05);
    color: crimson;
    }
}

.datatable .cellWithImg .cellImg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    margin-right: 10px;
}

.css-5wly58-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    white-space: pre-wrap !important;
}

 .MuiDataGrid-cell{
    min-height: 70px !important;

}
.MuiDataGrid-row{
    min-height: 70px !important;
    max-height: 100% !important;
}
.MuiDataGrid-row.Mui-selected{
            min-height: 70px !important;

}


.css-5wly58-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    min-height: 100% !important;
    max-height: 100% !important;
    overflow: initial !important;
}
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    min-height: 100% !important;
    max-height: 100% !important;
    overflow: initial !important;
    min-height: 100% !important;
    max-height: 100% !important;
}
.css-78d1bb .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell{
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    min-height: 100% !important;
    max-height: 100% !important;
    overflow: initial !important;
}
