.admin-home{
    .box-number{
        box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
        border-radius: 8px;
        padding: 10px 20px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .user{
            border: 3px solid #233a80;
            svg{
                color: #233a80;
                font-size: 30px;
            }
        }
        .company{
            border: 3px solid #dd6511;
            svg{
                color: #dd6511;
                font-size: 30px;
            }
        }
        .course{
            border: 3px solid #a56873;
            svg{
                color: #a56873;
                font-size: 30px;
            }
        }
        .Instructor{
            border: 3px solid green;
            svg{
                color: green;
                font-size: 30px;
            }
        }
        .box-icon{
            padding: 20px;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .box-title{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            gap: 5px;
            h6{
                font-weight: bold;
                font-size: 14px;
                color: black;
            }
            h4{
                font-size: 17px;
                font-weight: 400 !important;
                color: black;
            }
        }
    }
}